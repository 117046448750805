import AccentContainer from "../base/accent-container";
import Cta from "../base/cta";
import Grid from "../base/grid";

const StoryCta = ({ children }) => (
  <Grid.Column size="lg">
    <AccentContainer variant="cta" padding="lg">
      <Cta>{children}</Cta>
    </AccentContainer>
  </Grid.Column>
);

export default StoryCta;
